<template>
  <div id="orderproduct-list">
    <v-card>
      <v-card-title>注文実績一覧(定期便)</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="subs_code"
            :items="subsCodes"
            placeholder="定期便コード"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="orderStatus"
            :items="orderStatusMaster"
            placeholder="注文状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date"
                placeholder="注文日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date"
                placeholder="注文日To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="end_date"
              @input="dateEndMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'order-create', params: { query: this.$route.query } }"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="orderSubsList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                href="javascript:void(0);"
                :style="item.mbr_status === 9 ? 'text-decoration: line-through red; color:#696969' : 'text-decoration: underline; color:#696969' "
                v-on="on"
              >{{ item.mbr_sei }} {{ item.mbr_mei }}</a>
            </template>
            <span>{{ item.mbr_sei }}   {{ item.mbr_mei }}</span><br>
            <span>{{ item.mbr_sei_kana }}   {{ item.mbr_mei_kana }}</span><br>
            <span>{{ item.mbr_zip }}</span><br>
            <span>{{ item.mbr_address1 }}{{ item.mbr_address2 }}</span><br>
            <span>{{ item.mbr_email }}</span><br>
            <span>{{ item.mbr_mobile }}</span><br>
          </v-tooltip>
        </template>
        <template v-slot:[`item.order_dt`]="{ item }">
          <div>
            <span>{{ item.order_dt }}</span>
          </div>
        </template>
        <template v-slot:[`item.os_desc`]="{ item }">
          <div :style="item.os_code > 7 ? 'color:red':''">
            {{ item.os_desc }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
          </div>
        </template>
        <template v-slot:[`item.order_code`]="{ item }">
          <v-tooltip v-if="item.order_code" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              > {{ item.order_code }}</span>
            </template>
            <span>{{ item.type }}<br />{{ item.product_name }}<br />{{ item.price }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.order_tip`]="{ item }">
          <label>{{ item.order_tip.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`item.order_sum`]="{ item }">
          <label :style="item.os_code === 99 ? 'text-decoration: line-through' : '' ">{{ (item.order_tip * item.order_qty).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              style="background: #fafafa"
              :colspan="5"
            >
              <label>合計価格(円)</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ tableTotalPrice }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="3"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiCalendar,
  mdiMagnify,
  mdiPencil,
  mdiDelete,
  mdiExportVariant,
  mdiAutorenew,
  mdiPlus,
  mdiClipboardArrowDownOutline,
  mdiClipboardArrowUpOutline,
} from '@mdi/js'

// import DeleteForm from '@/views/bimi/product/item/ProductsDelete.vue'

export default {
  components: {
    // DeleteForm,
  },
  data: () => ({
    start_date: '',
    end_date: '',
    dateEndMenu: '',
    dateStartMenu: '',
    firstName: '',
    lastName: '',
    subs_code: '',
    arrBfo: [],
    search: '',
    orderStatus: '',
    icons: {
      mdiCalendar,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiAutorenew,
      mdiPlus,
      mdiClipboardArrowDownOutline,
      mdiClipboardArrowUpOutline,
    },
    headers: [
      {
        text: '注文ID',
        value: 'ord_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },

      // {
      //   text: '注文番号',
      //   value: 'order_no',
      //   align: 'left',
      //   width: '12%',
      //   fixed: true,
      // },
      {
        text: '会員氏名',
        align: 'left',
        sortable: true,
        value: 'name',
        width: '12%',
        fixed: true,
      },

      {
        text: '定期便名',
        value: 'product_name',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: true,
      },

      {
        text: '単価',
        value: 'order_tip',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '数量',
        value: 'order_qty',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '売上金額',
        value: 'order_sum',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },

      {
        text: '注文日時',
        value: 'order_dt',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '注文状態',
        value: 'os_desc',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
      {
        text: '姓',
        value: 'mbr_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'mbr_mei',
        align: ' d-none',
      },
      {
        text: 'セイ',
        value: 'mbr_sei_kana',
        align: ' d-none',
      },
      {
        text: 'メイ',
        value: 'mbr_mei_kana',
        align: ' d-none',
      },
    ],
  }),
  computed: {
    ...mapState('orderStore', ['orderSubsList', 'orderStatusMaster']),
    ...mapState('subscriptionStore', ['subsCodes']),
    tableTotalPrice() {
      let totalPrice = 0
      this.orderSubsList.forEach(osl => {
        if (osl.os_code !== 99 && osl.order_tip && osl.order_qty) {
          totalPrice += osl.order_tip * osl.order_qty
        }
      })

      return totalPrice.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
  },
  watch: {
    subs_code(newValue) {
      this.changeRouteQuery('subs_code', newValue)
      this.loadData()
    },
    orderStatus(newValue) {
      this.changeRouteQuery('os_code', newValue)
      this.loadData()
    },
    start_date(newValue) {
      this.changeRouteQuery('start_date', newValue)
      this.loadData()
    },
    end_date(newValue) {
      this.changeRouteQuery('end_date', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  destroyed() {
    this.clearOrderSubs()
    this.clearOrderStatusMaster()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('orderStore', ['clearOrderSubs', 'clearOrderStatusMaster']),
    ...mapActions('orderStore', ['loadOrderStatus', 'loadOrderSubs']),
    ...mapActions('subscriptionStore', ['loadSubscriptionCodes']),
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadSubscriptionCodes(),
        this.loadOrderStatus(),
        this.loadOrderSubs(this.$route.query),
      ]).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    editItem(item) {
      console.log(item)

      // this.$router.push({
      //   name: 'order-edit',
      //   params: {
      //     id: item.crushed_id, query: this.$route.query,
      //   },
      // })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.subs_code = this.$route.query.subs_code ?? ''
      this.start_date = this.$route.query.start_date ?? ''
      this.end_date = this.$route.query.end_date ?? ''
      // eslint-disable-next-line radix
      this.orderStatus = this.$route.query.os_code ? parseInt(this.$route.query.os_code) : ''
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
